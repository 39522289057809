export default {
  saveWallets(state, payload) {
    state.wallets = payload.payload;
  },

  saveWalletHistory(state, payload) {
    state.history = payload;
  },

  saveWalletHistoryCount(state, payload) {
    state.historyCount = payload.count;
  },

  saveWalletStats(state, { payload, misc }) {
    state.pendingPayment = payload.amount_pending;
    state.paidInTotal = payload.amount_paid;
    state.has_approved_not_paid_payment = payload.has_approved_not_paid_payment;
    state.currencyCode = misc.currency_code;
  },

  countBalance(state) {
    const activeWallet = state.wallets.find(wallet => wallet.status === 'active') || {}
    const availableBalance = activeWallet.available_balance || 0;
    const holdBalance = activeWallet.hold_balance || 0;

    state.availableBalance = availableBalance;
    state.holdBalance = holdBalance;
    state.balance = (availableBalance + holdBalance);
  },
};
