export default {
  data() {
    return {
      currencyCode: '',
    };
  },
  computed: {
    currencyLabel() {
      return this.getCurrencyLabel(this.currencyCode);
    },
  },
  methods: {
    getCurrencyLabel(code) {
      if (!code) {
        return '';
      }

      const currency = this.$store.state.misc.currencies.find((el => el.code === code));

      return currency?.symbol || `${code} `;
    },
  },
};
