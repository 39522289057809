export default () => ({
  channels: [],
  channel: {},
  campaigns: [],
  campaignsCount: 0,
  campaignsCurrencyCode: null,
  campaignsAttentionCounters: {
    needs_attention_payments: 0,
    needs_attention_posts: 0,
    needs_attention_total: 0,
  },
  campaign: {},
  campaignAvailableSpend: {},
  posts: [],
  post: {},
  payments: [],
  payment: {},
  totals: {},
  postsCount: 0,
  postsCountSidebar: 0,
  postsCountChannels: 0,
  postsCountCampaigns: 0,
  paymentsCount: 0,
  paymentsCountSidebarModeration: 0,
  paymentsCountSidebarPayout: 0,
  paymentsCountModeration: 0,
  paymentsCountPayout: 0,
  uploadSettingsData: {},
  trackingUrls: [],
  trackingUrl: [],
  landings: [],
  paymentMethod: [],
  marketingCampaigns: [],
});
