<template>
  <section
    class="public-table media-buying-ad"
    :class="{
      wavebg: loading,
    }"
  >
    <table-card
      ref="datatable"
      :loading="loading"
      :columns="columns"
      :sort.sync="sorting"
      :sort-by-name="sortByName"
      :sort-dir-name="sortDirName"
      :rows="rows"
      :currency="currencyLabel"
      :page.sync="page"
      :page-size.sync="pageSize"
      :count="$store.state.mediaBuying.campaignsCount"
      :formaters="formaters"
      :actions="actions"
      :header="$t('mediaBuying.campaign.nested_campaigns')"
      is-static
      dont-fill-empty
      custom-context
      :disable-actions="isArchived"
      :has-pagination="true"
      placementTooltip="bottom"
      i18n-path="mediaBuying.channels"
      @fetch-data="fetchDataTable"
      @checkedRows="handleCheckedRows"
    >
      <div
        slot="header"
        :class="[
          'ui-d-flex',
          'ui-desktop-no-wrap',
          'ui-mobile-wrap',
          'ui-mobile-12',
        ]"
      >
        <table-card-search-header
          v-model="search"
          class="ui-g-md"
          @search="handleSearch"
        ></table-card-search-header>

        <v-select
          :id="`${$randomizer()}status`"
          v-model="status"
          :options="statusFilters"
          class="ui-select2 ui-align-center ui-mobile-12 ui-g-md"
          style="width: 200px"
          @change="fetchData"
          :settings="statusFiltersSettings"
        />
        <div
          class="checkbox payment-history__checkbox ui-mobile-12 ui-g-md"
        >
          <input
            v-model="actionRequired"
            id="checkbox1"
            type="checkbox"
            @change="update"
          >
          <label
            class="checkbox-label checkbox-label__action-required"
            for="checkbox1"
          >
            {{ $t('mediaBuying.campaign.action_required') }}
          </label>
        </div>
      </div>
      <div slot="actionsPrepend" class="ui-g-md ui-mobile-12">
        <button
          class="btn btn-primary btn-sm ui-mobile-12"
          :disabled="isArchived"
          @click="addCampaign"
        >
          + {{ $t('mediaBuying.campaigns.add_campaign') }}
        </button>
      </div>
    </table-card>

    <edit-campaign
      ref="editCampaign"
      id="adversitingCampaign"
      @addCampaign="fetchData"
    />
  </section>
</template>

<script>
import formattedCurrency from 'src/helpers/formattedCurrency.js';
import UrlMixin from 'src/components/Query/url-mixin';
import UrlMixinHooks from 'src/components/Query/url-mixin-hooks';
import InjectPlugins from 'src/components/Report/Components/inject-plugins';
import { pageSizeMixin } from 'src/views/Dashboard/Reports/page_size.js';
import EditCampaign from 'src/views/Dashboard/MediaBuying/Modal/EditCampaign';
import Mobtable from 'src/components/Mobiletable/Mobtable';
import Formatters from 'src/views/Dashboard/CustomReports/formaters';
import SortBy from 'src/components/SortBy.vue';
import TableCard from 'src/components/Cards/TableCard.vue';
import tableFlowMixinGenerator from 'src/views/Dashboard/Reports/tableFlowMixin.js';
import TableCardSearchHeader from 'src/components/Cards/TableCardSearchHeader.vue';
import formatCurrency from '@/mixins/format-currency';

const fieldMap = {
  post_status: 'status',
  post_type: 'type',
  // period: 'post_period_start',
  post_sum: 'amount',
  status: 'post_status',
  type: 'post_type',
  post_period_start: 'period',
  amount: 'post_sum',
};

const classes = {
  approved: 'text-green',
  on_review: 'text-orange',
  declined: 'text-red',
  paid: 'text-green',
  processing: 'text-orange',
  not_paid: 'text-red',
  for_rework: 'text-red',
  failed: 'text-red',
};

const defaultParams = {
  prop: 'created_at',
  order: 'desc',

  search: '',
  needs_attention_first: true,
  status: 'active',

  page: 1,
  pageSize: 20,
};

const tableFlowMixin = tableFlowMixinGenerator({
  defaultParams: {
    ...defaultParams,
  },
  params: Object.keys(defaultParams),
  sortByName: 'prop',
  sortDirName: 'order',
  fetchOnActivated: false,

  offset: 0,
  limit: defaultParams.pageSize,
});

export default {
  components: {
    SortBy,
    Mobtable,
    EditCampaign,
    TableCard,
    TableCardSearchHeader,
  },
  mixins: [
    UrlMixin,
    UrlMixinHooks,
    InjectPlugins,
    pageSizeMixin,
    tableFlowMixin,
    formatCurrency,
  ],

  props: [
    'campaignName', 'statusChannel', 'statusCampaign', 'activeTab',
    'tableSorting', 'rootCampaignId', 'needsAttention', 'nestedSettingView',
  ],

  data() {
    return {
      status: 'active',
      actionRequired: true,
      search: '',
      statusFiltersSettings: {
        minimumResultsForSearch: Infinity,
      },

      actions: {
        width: 80,
        name: this.$t('profile.apiKeys.table.actions'),
        fixed: 'right',
        operations: [
          {
            name: 'show',
            component: (row, ctx) => {
              const { handleCheckedRows } = this;
              const span = ctx.$createElement(
                'span',
                {
                  on: {
                    click(e) {
                      e.cancelBubble = true;
                      handleCheckedRows(null, null, row);
                    },
                  },
                },
                [
                  ctx.$createElement('i', {
                    attrs: {
                      class: 'far fa-eye text-green',
                    },
                  }),
                ],
              );
              return ctx.$createElement(
                'el-tooltip',
                {
                  attrs: {
                    class: 'el-tooltip icon__wrapper text-green show-channel',
                    placement: 'top',
                    content: this.$t('mediaBuying.channels.show'),
                    'open-delay': 500,
                  },
                },
                [span],
              );
            },
          },
          {
            name: 'edit',
            component: (row, ctx) => {
              const { editCampaign } = this;
              const span = ctx.$createElement(
                'span',
                {
                  on: {
                    click(e) {
                      e.stopPropagation();
                      editCampaign(row);
                    },
                  },
                },
                [
                  ctx.$createElement('i', {
                    attrs: {
                      class: 'far fa-pen',
                      disabled: false,
                    },
                  }),
                ],
              );
              const elTooltip = ctx.$createElement(
                'el-tooltip',
                {
                  attrs: {
                    class: 'el-tooltip icon__wrapper text-green',
                    placement: 'top',
                    content: this.$t('mediaBuying.channels.edit'),
                    'open-delay': 500,
                  },
                },
                [span],
              );

              return row.status === 'active' && elTooltip;
            },
          },
          {
            name: 'archive',
            component: (row, ctx) => {
              const { editStatusCampaign } = this;
              const disabled = false;
              const span = ctx.$createElement(
                'span',
                {
                  attrs: {
                    disabled,
                  },
                  on: {
                    click(e) {
                      e.stopPropagation();
                    },
                  },
                },
                [
                  ctx.$createElement('i', {
                    attrs: {
                      class: 'far fa-archive',
                    },
                  }),
                ],
              );
              const elTooltip = ctx.$createElement(
                'el-tooltip',
                {
                  attrs: {
                    class: 'el-tooltip icon__wrapper text-red',
                    placement: 'top',
                    content: this.$t('mediaBuying.channels.archive'),
                    'open-delay': 500,
                  },
                },
                [span],
              );
              const elPopover = ctx.$createElement(
                'ac-popover',
                {
                  attrs: {
                    width: 250,
                    message: this.$t('mediaBuying.campaigns.archive_message'),
                    buttons: [
                      {
                        text: this.$t('mediaBuying.channels.cancel'),
                      },
                      {
                        text: this.$t('mediaBuying.channels.archive'),
                        type: 'danger',
                        click: () => editStatusCampaign(row),
                      },
                    ],
                    disabled,
                  },
                },
                [elTooltip],
              );
              return row.status === 'active' && elPopover;
            },
          },
          {
            name: 'active',
            component: (row, ctx) => {
              const { editStatusCampaign } = this;
              const span = ctx.$createElement(
                'span',
                {
                  on: {
                    click(e) {
                      e.stopPropagation();
                    },
                  },
                },
                [
                  ctx.$createElement('i', {
                    attrs: {
                      class: 'far fa-history',
                    },
                  }),
                ],
              );
              const elTooltip = ctx.$createElement(
                'el-tooltip',
                {
                  attrs: {
                    class: `el-tooltip icon__wrapper text-green ${
                      // this.statusChannel ? 'disabled' : ''
                      ''
                    }`,
                    placement: 'top',
                    content: this.$t('mediaBuying.channels.restore'),
                    'open-delay': 500,
                  },
                },
                [span],
              );
              const elPopover = ctx.$createElement(
                'ac-popover',
                {
                  attrs: {
                    width: 270,
                    message: this.$t('mediaBuying.campaigns.restore_message'),
                    buttons: [
                      {
                        text: this.$t('mediaBuying.channels.cancel'),
                      },
                      {
                        text: this.$t('mediaBuying.channels.restore'),
                        type: 'success',
                        click: () => editStatusCampaign(row),
                      },
                    ],
                  },
                },
                [elTooltip],
              );
              return row.status === 'archived' && elPopover;
            },
          },
        ],
      },
      loading: false,
      formaters: Formatters,
    };
  },
  computed: {
    currencyLabel() {
      return this.getCurrencyLabel(this.$store.state.mediaBuying.campaignsCurrencyCode)
    },
    columns() {
      return [
        {
          name: 'incrementing_id',
          align: 'left',
          filterable: false,
          sortable: 'custom',
          width: 60,
        },
        {
          name: 'channel_status',
          align: 'center',
          filterable: false,
          width: 90,
          renderValue: v => `<span class="${v === 'active' ? 'green' : 'red'}">${this.$t(
            `mediaBuying.channels.camp_status.${v}`,
          )}</span>`,
        },
        {
          name: 'campaign_name',
          align: 'left',
          minWidth: 200,
          customTooltip: true,
          renderValue: v => `<span class="name-bubble ffff d-flex justify-content-between">
                              <span class="dark-green">${v.text}</span>
                            </span>`,
        },
        this.nestedSettingView === 'only_nested' ? {
          name: 'balance_sum_approved',
          align: 'right',
          sortable: 'custom',
          width: 130,
          format: 'currency',
        } : { del: true },
        this.nestedSettingView === 'only_nested' ? {
          name: 'balance_sum_total',
          align: 'right',
          sortable: 'custom',
          width: 130,
          format: 'currency',
        } : { del: true },
        this.nestedSettingView === 'only_nested' ? {
          name: 'paid_sum_approved',
          align: 'right',
          filterable: false,
          sortable: 'custom',
          width: 130,
          format: 'currency',
        } : { del: true },
        this.nestedSettingView === 'only_nested' ? {
          name: 'paid_sum_pending',
          align: 'right',
          sortable: 'custom',
          width: 130,
          format: 'currency',
        } : { del: true },
        this.nestedSettingView === 'only_nested' ? {
          name: 'spent_sum_approved',
          align: 'right',
          sortable: 'custom',
          width: 130,
          format: 'currency',
        } : { del: true },
        this.nestedSettingView === 'only_nested' ? {
          name: 'spent_sum_pending',
          align: 'right',
          sortable: 'custom',
          width: 130,
          format: 'currency',
        } : { del: true },
        this.nestedSettingView === 'only_payment' || this.nestedSettingView === 'only_nested' ? {
          name: 'registrations_count',
          align: 'right',
          // sortable: 'custom',
          width: 110,
        } : { del: true },
        this.nestedSettingView === 'only_payment' || this.nestedSettingView === 'only_nested' ? {
          name: 'first_deposits_count',
          align: 'right',
          filterable: false,
          // sortable: 'custom',
          width: 110,
        } : { del: true },
        this.nestedSettingView === 'only_payment' || this.nestedSettingView === 'only_nested' ? {
          name: 'deposits_sum',
          align: 'right',
          filterable: false,
          // sortable: 'custom',
          format: 'ncurrency',
          width: 110,
        } : { del: true },
        this.nestedSettingView === 'only_payment' || this.nestedSettingView === 'only_nested' ? {
          name: 'profit_sum',
          align: 'right',
          filterable: false,
          // sortable: 'custom',
          format: 'ncurrency',
          width: 110,
          renderValue: this.formattedCurrencyColor,
        } : { del: true },
        this.nestedSettingView === 'only_payment' || this.nestedSettingView === 'only_nested' ? {
          name: 'cost_per_player',
          align: 'right',
          filterable: false,
          // sortable: 'custom',
          format: 'currency',
          width: 110,
        } : { del: true },
        {
          name: 'created_at',
          align: 'left',
          filterable: false,
          sortable: 'custom',
          format: 'date-time',
          width: 150,
        },
        {
          name: 'updated_at',
          align: 'left',
          filterable: false,
          sortable: 'custom',
          format: 'date-time',
          width: 150,
        },
      ].filter(el => !el.del);
    },

    statusFilters() {
      return [
        {
          id: '',
          text: this.$t('mediaBuying.campaigns.all_campaigns'),
        },
        {
          id: 'active',
          text: this.$t('mediaBuying.campaigns.active_campaigns'),
        },
        {
          id: 'archived',
          text: this.$t('mediaBuying.campaigns.archived_campaigns'),
        },
      ];
    },

    rows() {
      return this.$store.state.mediaBuying.campaigns.map(p => ({
        ...p,
        channel_status: p.status,
        campaign_name: {
          text: p.name,
          count: p.needs_attention_total,
        },
        balance_sum: this.currencyFormatter(
          p.balance_sum_usd_total,
          this.currencyLabel,
        ),
        spent_sum: this.currencyFormatter(
          p.spent_sum_usd_total,
          this.currencyLabel,
        ),
        paid_sum: this.currencyFormatter(p.paid_sum_usd_total, 'usd'),
      }));
    },

    isArchived() {
      return this.statusCampaign === 'archived';
    },
  },
  methods: {
    formattedCurrencyColor(v) {
      const amount = v;
      let sum = this.$options.filters.currency(amount, this.currencyLabel, null, {
        symbolOnLeft: true,
        spaceBetweenAmountAndSymbol: false,
        decimalSeparator: ',',
      });
      sum = sum.includes('NaN') ? amount : sum;

      if (amount < 0) {
        return `<span class="text-red">${sum}</span>`;
      }

      if (amount > 0) {
        return `<span class="text-green">${sum}</span>`;
      }

      return `<span>${sum}</span>`;
    },

    handleCheckedRows(id, ids, row) {
      const needsAttention = this.$_.cloneDeep(this.needsAttention);
      const nestedSettingView = this.$_.cloneDeep(this.nestedSettingView);
      localStorage.setItem(
        'getBackUrlCampaign',
        JSON.stringify(this.$route.path),
      );
      this.$router.push(
        `/dashboard/media-buying-campaigns-sub/${row.id}/${this.compressUrlQuery({
          ...row,
          campaign_name: this.campaignName,
          campaign_id: row.id,
          status: '',
          needsAttention: {
            payments: row.needs_attention_payments,
            posts: row.needs_attention_posts,
            total: row.needs_attention_total,
          },
          nestedSettingView,
        })}`,
      );

      // this.update('hard');

      // document.removeEventListener('mouseup', this.listener);
    },

    addCampaign() {
      this.$refs.editCampaign.open({
        root_campaign_id: this.rootCampaignId,
        edited: false,
      });
    },

    editCampaign(row) {
      this.$refs.editCampaign.open({
        ...row,
        name: row.name,
        edited: true,
      });
    },
    addPost(row) {
      const balanceEmpty = Object.values(this.$store.state.mediaBuying.campaign.balances?.approved || {})
        .every(el => el <= 0);

      if (!balanceEmpty || row.id) {
        this.$refs.addPost.open(row);
      } else {
        this.$alert.error(this.$t('mediaBuying.campaign.zero_balance_error'));
      }
    },
    showPost(row) {
      this.$refs.showPost.open(row);
    },

    async editStatusCampaign(row) {
      if (row.status === 'active') {
        this.$store.dispatch('postCampaignArchive', row.id)
          .then(() => {
            this.fetchData();
            this.$alert.success(
              this.$t('mediaBuying.campaigns.archive_success_message'),
            );
          })
          .catch(e => {
            const { message } = e.response.data;

            if (message) {
              this.$alert.error(message);
            }
          });
      } else {
        await this.$store.dispatch('postCampaignActivate', row.id);
        this.fetchData();
        this.$alert.success(
          this.$t('mediaBuying.campaigns.restore_success_message'),
        );
      }
    },

    update(action) {
      this.$emit('update', {
        tab: 'campaign',
        prop: this.sorting.prop,
        order: this.sorting.order,
        action,

        needs_attention_first: this.actionRequired,
        status: this.status,
        search: this.search,

        limit: this.pageSize,
        offset: (this.page - 1) * this.pageSize,
      });
    },

    async fetchData() {
      this.update();
    },
    currencyFormatter(amount, code) {
      amount = amount.toFixed(2);
      const amountData = amount.split('.');
      const result = (+amountData[0]).toLocaleString();

      return `${this.getCurrencyLabel(code)}${result}.${amountData[1]}`;
    },
  },
  created() {
    this.actions.operations.map((e) => {
      if (e.component) {
        e.component = e.component.bind(this);
      }
    });
  },

  activated() {},

  watch: {
    activeTab() {
      if (this.activeTab === 'campaign') {
        this.update();
        // this.$store.dispatch('getPostPlatforms', {});
      }
    },
    tableSorting: {
      immediate: true,
      deep: true,
      handler(value) {
        this.sorting = {
          prop: fieldMap[value.sort_column] || value.sort_column,
          order: value.sort_dir,
        };
      },
    },
  },
};
</script>
