export default () => ({
  paymentHistory: [],
  filter: '',
  wallets: [],
  history: [],
  historyCount: 0,
  balance: 0,
  availableBalance: 0,
  holdBalance: 0,
  pendingPayment: 0,
  paidInTotal: 0,
  currencyCode: null,
  has_approved_not_paid_payment: '',
});
