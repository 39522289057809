var render = function render(){var _vm=this,_c=_vm._self._c;return _c('section',{staticClass:"public-table media-buying-ad",class:{
    wavebg: _vm.loading,
  }},[_c('table-card',{ref:"datatable",attrs:{"loading":_vm.loading,"columns":_vm.columns,"sort":_vm.sorting,"sort-by-name":_vm.sortByName,"sort-dir-name":_vm.sortDirName,"rows":_vm.rows,"currency":_vm.currencyLabel,"page":_vm.page,"page-size":_vm.pageSize,"count":_vm.$store.state.mediaBuying.campaignsCount,"formaters":_vm.formaters,"actions":_vm.actions,"header":_vm.$t('mediaBuying.campaign.nested_campaigns'),"is-static":"","dont-fill-empty":"","custom-context":"","disable-actions":_vm.isArchived,"has-pagination":true,"placementTooltip":"bottom","i18n-path":"mediaBuying.channels"},on:{"update:sort":function($event){_vm.sorting=$event},"update:page":function($event){_vm.page=$event},"update:pageSize":function($event){_vm.pageSize=$event},"update:page-size":function($event){_vm.pageSize=$event},"fetch-data":_vm.fetchDataTable,"checkedRows":_vm.handleCheckedRows}},[_c('div',{class:[
        'ui-d-flex',
        'ui-desktop-no-wrap',
        'ui-mobile-wrap',
        'ui-mobile-12',
      ],attrs:{"slot":"header"},slot:"header"},[_c('table-card-search-header',{staticClass:"ui-g-md",on:{"search":_vm.handleSearch},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}}),_c('v-select',{staticClass:"ui-select2 ui-align-center ui-mobile-12 ui-g-md",staticStyle:{"width":"200px"},attrs:{"id":`${_vm.$randomizer()}status`,"options":_vm.statusFilters,"settings":_vm.statusFiltersSettings},on:{"change":_vm.fetchData},model:{value:(_vm.status),callback:function ($$v) {_vm.status=$$v},expression:"status"}}),_c('div',{staticClass:"checkbox payment-history__checkbox ui-mobile-12 ui-g-md"},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.actionRequired),expression:"actionRequired"}],attrs:{"id":"checkbox1","type":"checkbox"},domProps:{"checked":Array.isArray(_vm.actionRequired)?_vm._i(_vm.actionRequired,null)>-1:(_vm.actionRequired)},on:{"change":[function($event){var $$a=_vm.actionRequired,$$el=$event.target,$$c=$$el.checked?(true):(false);if(Array.isArray($$a)){var $$v=null,$$i=_vm._i($$a,$$v);if($$el.checked){$$i<0&&(_vm.actionRequired=$$a.concat([$$v]))}else{$$i>-1&&(_vm.actionRequired=$$a.slice(0,$$i).concat($$a.slice($$i+1)))}}else{_vm.actionRequired=$$c}},_vm.update]}}),_c('label',{staticClass:"checkbox-label checkbox-label__action-required",attrs:{"for":"checkbox1"}},[_vm._v(" "+_vm._s(_vm.$t('mediaBuying.campaign.action_required'))+" ")])])],1),_c('div',{staticClass:"ui-g-md ui-mobile-12",attrs:{"slot":"actionsPrepend"},slot:"actionsPrepend"},[_c('button',{staticClass:"btn btn-primary btn-sm ui-mobile-12",attrs:{"disabled":_vm.isArchived},on:{"click":_vm.addCampaign}},[_vm._v(" + "+_vm._s(_vm.$t('mediaBuying.campaigns.add_campaign'))+" ")])])]),_c('edit-campaign',{ref:"editCampaign",attrs:{"id":"adversitingCampaign"},on:{"addCampaign":_vm.fetchData}})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }