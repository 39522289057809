import moment from 'moment';

export default {
  install(Vue, options) {
    Vue.filter('percent', (value) => {
      if (!value) return '';
      return `${value.toFixed(2)} %`;
    });

    Vue.filter('currencyInt', (value) => {
      if (!value) return 0;
      return parseFloat(value / 100).toFixed(2);
    });

    // https://github.com/freearhey/vue2-filters
    Vue.filter('currency', (value, symbol, decimals, options) => (symbol + parseFloat(value).toLocaleString('en-US', { currency: 'USD', maximumFractionDigits: 2, minimumFractionDigits: 2 })));

    Vue.filter('formatDate3', value => moment(value).locale(Vue.prototype.$locale || 'en').format('DD MMM YYYY HH:mm'));
  },
};
